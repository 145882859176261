import React from "react"
import * as Styled from "./Landing.styles"
import { Typography } from "@mui/material"

export const InfoTechnoparkItem = ({ image, description }: { image: string; description: string }) => {
  return (
    <Styled.InfoTechnoparkItem spacing={2}>
      <img src={image} />
      <Typography variant={"body1"} fontWeight={"medium"} fontSize={"small"} sx={{ color: "#313439" }}>
        {description}
      </Typography>
    </Styled.InfoTechnoparkItem>
  )
}
