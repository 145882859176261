//@ts-nocheck
import React from "react"
import { Tab, Tabs, Typography } from "@mui/material"
import * as Styled from "./Catalog.styles"

export const CourseTypes = ({ tab, setTab }: { tab: number; setTab: React.Dispatch<React.SetStateAction<number>> }) => {
  return (
    <Styled.CourseTypesMainDiv>
      <Tabs
        //@ts-ignore
        textColor={"#313439"}
        value={tab}
        TabIndicatorProps={{ style: { height: 6, borderRadius: 3, backgroundColor: "#313439" } }}
        onChange={(e, newValue) => setTab(newValue as number)}
      >
        <Tab
          label={
            <Typography sx={{ color: "#313439" }} variant={"subtitle3"}>
              Все
            </Typography>
          }
          sx={{ textTransform: "none" }}
          value={0}
          style={{ fontSize: 18 }}
        />
        <Tab
          label={
            <Typography sx={{ color: "#313439" }} variant={"subtitle3"}>
              События
            </Typography>
          }
          sx={{ textTransform: "none" }}
          value={1}
          style={{ fontSize: 18 }}
        />
        <Tab
          label={<Typography variant={"subtitle3"}>Встречи</Typography>}
          sx={{ textTransform: "none" }}
          value={2}
          style={{ fontSize: 18 }}
        />
      </Tabs>
      <hr style={{ marginTop: -3, color: "#313439" }} />
    </Styled.CourseTypesMainDiv>
  )
}
