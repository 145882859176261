import React from "react"
import { Link, Typography } from "@mui/material"
import { Button } from "shared/ui"
import { useTheme } from "shared/context"
import * as Styled from "./styles"

interface IProps {
  slug: string
  type: "event" | "webinar"
  participantData: { has_certificate: boolean; is_completed: boolean }
}

export const InProgressContentMapper = ({ type, slug, participantData }: IProps) => {
  const theme = useTheme()

  const contentMapper = () => {
    if (type === "webinar") {
      if (participantData.is_completed && !participantData.has_certificate) {
        return (
          <Button
            onClick={() => window.open(`/event/${slug}/certificate`, "_blank")}
            variant="contained"
            //@ts-ignore
            color={"accent"}
            //@ts-ignore
            height={"custom"}
            sx={{
              height: theme.spacing(14),
              paddingLeft: theme.spacing(12),
              paddingRight: theme.spacing(12),
              borderRadius: theme.spacing(3),
              fontSize: theme.spacing(5),
              [theme.breakpoints.down("xs")]: { width: "100%", padding: "auto" },
            }}
          >
            ПОЛУЧИТЬ СЕРТИФИКАТ
          </Button>
        )
      } else if (participantData.is_completed && participantData.has_certificate) {
        return (
          <Button
            onClick={() => window.open(`/event/${slug}/certificate`, "_blank")}
            variant="outlined"
            //@ts-ignore
            color={"accent"}
            //@ts-ignore
            height={"custom"}
            sx={{
              height: theme.spacing(14),
              paddingLeft: theme.spacing(12),
              paddingRight: theme.spacing(12),
              borderRadius: theme.spacing(3),
              fontSize: theme.spacing(5),
              [theme.breakpoints.down("xs")]: { width: "100%", padding: "auto" },
            }}
          >
            ОТКРЫТЬ СЕРТИФИКАТ
          </Button>
        )
      } else
        return <StatusWebinarMessage text={"Вы являетесь участником встречи. "} linkText={"Перейти в мои встречи "} />
    } else
      return (
        <Button
          component={Link}
          href={`/event/${slug}/stages`}
          variant="contained"
          //@ts-ignore
          color={"accent"}
          //@ts-ignore
          height={"custom"}
          sx={{
            height: theme.spacing(14),
            paddingLeft: theme.spacing(12),
            paddingRight: theme.spacing(12),
            borderRadius: theme.spacing(3),
            fontSize: theme.spacing(5),
            [theme.breakpoints.down("xs")]: { width: "100%", padding: "auto" },
          }}
        >
          ТРАЕКТОРИЯ ПРОЕКТА
        </Button>
      )
  }

  return <>{contentMapper()}</>
}

export const StatusWebinarMessage = ({ text, linkText }: { text: string; linkText: string }) => {
  return (
    <Styled.StatusMessageDiv>
      <Styled.StatusOutlinedIcon />
      <div>
        <Styled.StatusText variant={"body1"} fontWeight={"medium"} fontSize={"small"}>
          {text}
          <Styled.StatusLinkText
            variant={"body1"}
            fontWeight={"medium"}
            fontSize={"small"}
            onClick={() => {
              window.location.href = "/statistics/webinars"
            }}
          >
            {linkText}
          </Styled.StatusLinkText>
        </Styled.StatusText>
      </div>
    </Styled.StatusMessageDiv>
  )
}
