import React from "react"

import * as Styled from "./Catalog.styles"
import { Typography } from "@mui/material"

export const CoursesNotFound = ({
  tab,
  setTab,
}: {
  tab: number
  setTab: React.Dispatch<React.SetStateAction<number>>
}) => {
  const getText = () => {
    switch (tab) {
      case 0:
        return "Нет мероприятий, соответствующих фильтру."

      case 1:
        return "Нет Событий, соответствующих фильтру. "

      case 2:
        return "Нет Встреч, соответствующих фильтру. "
    }
  }

  function setInitState() {
    setTab(0)
  }

  return (
    <Styled.CoursesNotFoundMainDiv>
      <Typography variant={"body1"} fontSize={"medium"} fontWeight={"medium"} sx={{ textAlign: "center" }}>
        {getText()}
      </Typography>
      {tab !== 0 && (
        <Typography
          sx={{ color: "#3483F6", textDecoration: "underline", cursor: "pointer", textAlign: "center" }}
          onClick={setInitState}
        >
          Применить фильтр ко Всем мероприятиям
        </Typography>
      )}
    </Styled.CoursesNotFoundMainDiv>
  )
}
