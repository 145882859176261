import { Typography, Grid } from "@mui/material"
import { parseText, parseDate, parseTime } from "../util/functions"
import { useState } from "react"
import { ActionButtons } from "./ActionButtons"
import { CollapseTextButton } from "./CollapseTextButton"
import { NewNotifyChip } from "./NewNotifyChip"

export function NotificationItem(props) {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const { link, creation_time, is_received, title, content, type, additional_info } = props 
  const isButtons = type === "action" && !is_received
  const goToPages = () => (window.location.href = link)

  const handleClick = () => {
    link && goToPages()
  }

  return (
    <Grid
      container
      spacing={0}
      onClick={handleClick}
      style={link ? { cursor: "pointer" } : {}}
      sx={
        link
          ? { padding: 6, borderBottom: "1px solid #E0E0E0", "&:hover": { backgroundColor: "#F0F0F0" } }
          : { padding: 6, borderBottom: "1px solid #E0E0E0" }
      }
    >
      <Grid item xs={3} style={{ justifyContent: "center" }}>
        <Typography fontSize={"small"} fontWeight={"small"} variant={"body2"}>
          {parseDate(creation_time)}
        </Typography>
        <Typography fontSize={"small"} fontWeight={"small"} variant={"body2"}>
          {parseTime(creation_time)}
        </Typography>
        {!is_received && <NewNotifyChip />}
      </Grid>
      <Grid item container spacing={0} xs={9}>
        <Grid item xs={12} style={{ justifyContent: "center" }}>
          <Typography variant="subtitle3">{title}</Typography>
        </Grid>
        <Grid item xs={12}>
          {parseText(content, isCollapsed)}
          <CollapseTextButton content={content} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
          {isButtons && <ActionButtons additional_info={additional_info} />}
        </Grid>
      </Grid>
    </Grid>
  )
}
