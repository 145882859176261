import { IParticipant } from "entities/Participant/model/participant.types"
import { TestTypes } from "entities/Test/lib/Test.types"
import { BlockType } from "entities/Test/model/test.types"

interface IAssingmentBase {
  attachments: IAttachment[]
  attempts: number
  block_max_score: number
  block_min_score: number
  block_title: string
  block_type: BlockType
  content_block: number
  course: number
  creation_time: string
  feedback: null | string
  id: number
  participant: IParticipant
  score: number
  status: AssignmentStatus
  team: null
}

export interface IAssingment extends IAssingmentBase {
  task_submissions: ITaskSubmission[]
}

export interface IAssingmentReview extends IAssingmentBase {
  task_submissions: IReviewSubmision[]
  scores: { min: number; max: number }
}

export interface IAttachment {
  file: string
}

export interface ITaskSubmission {
  id: number
  key: string
  submission: number
  score: number
  component: ITaskComponent
  type: TestTypes
}

export interface IReviewSubmision {
  answer: string
  question: string
  type: TestTypes
}

export interface ITaskComponent {
  content_block: number
  id: number
  index: number
  props: string
  score: number
  task: number
  type: string
}

export enum AssignmentStatus {
  submitted = "submitted",
  accepted = "accepted",
  rejected = "rejected",
}
