import React, { useState } from "react"
import { Button, TextField, Typography } from "@mui/material"
import { TestsEditor } from "widgets"

import { axiosInstance as axios } from "shared/api/axiosInstance"
import Cookies from "js-cookie"

export const CreateContent = () => {
  const [courseId, setCourseId] = useState("1")
  const [themeId, setThemeId] = useState("1")
  const [moduleId, setModuleId] = useState(null)
  const [componentId, setComponentId] = useState(null)
  const [content, setContent] = useState({})
  const [helperText, setHelperText] = useState("")

  function updateContent(value, field) {
    content[field] = value
    setContent({ ...content })
    setHelperText("")
  }

  function clearOnUpdate() {
    setContent({})
  }

  function findContentBlock() {
    if (!courseId || !themeId || !moduleId || !componentId) return
    axios
      .get(`/content/modules/${courseId}/`)
      .then(res => {
        let subsectionId
        for (let section of res.data.sections) {
          if (section.index == themeId) {
            for (let subsection of section.subsections) {
              if (subsection.index == moduleId) {
                subsectionId = subsection.id
                break
              }
            }
            break
          }
        }
        axios
          .get(`/content/subsections/${subsectionId}/`)
          .then(r => {
            let content_block = null
            for (let block of r.data.blocks) {
              if (block.index == componentId) {
                content_block = block
                break
              }
            }
            if (!!content_block) {
              setHelperText("Already exists")
            } else {
              setHelperText("Does not exist")
            }
          })
          .catch(err => {
            setHelperText("Does not exist")
          })
      })
      .catch(err => {
        setHelperText("Does not exist")
      })
  }

  function createComponent() {
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    if (content.type === "multipart-content") content.props = JSON.stringify({ content: "" })
    axios.get(`/content/modules/${courseId}/`).then(res => {
      let subsectionId
      for (let section of res.data.sections) {
        if (section.index == themeId) {
          for (let subsection of section.subsections) {
            if (subsection.index == moduleId) {
              subsectionId = subsection.id
              break
            }
          }
          break
        }
      }
      let newBlock = { ...content, subsection: subsectionId, module: courseId }
      axios
        .post("/content/content-blocks/", newBlock)
        .then(r => {
          if (!!newBlock.components) {
            newBlock.components.forEach(component => {
              axios.post("/content/components/", { ...component, content_block: r.data.id }).then(c => {
                if (!!component.task) {
                  axios.post(`/content/tasks/`, { ...component.task, component: c.data.id }).catch(err => {
                    setHelperText("Error in components")
                    clearOnUpdate()
                    window.scrollTo(0, 0)
                  })
                }
              })
            })
          }
          setHelperText("Confirmed")
          clearOnUpdate()
          window.scrollTo(0, 0)
        })
        .catch(err => {
          const errors = Object.entries(err.response.data)
          const errorMessage = errors.map(err => `Ошибка в поле ${err[0]}: ${err[1]}`)
          setHelperText(errorMessage)
          window.scrollTo(0, 0)
        })
    })
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", width: 1200 }}>
        <TextField
          id="courseId"
          variant="outlined"
          value={courseId}
          onChange={e => setCourseId(e.target.value)}
          helperText="ID модуля"
          fullWidth
          style={{ margin: 48 }}
        />
        <TextField
          id="themeId"
          variant="outlined"
          value={themeId}
          onChange={e => setThemeId(e.target.value)}
          helperText="Индекс раздела"
          fullWidth
          style={{ margin: 48 }}
        />
        <TextField
          id="moduleId"
          variant="outlined"
          value={moduleId}
          onChange={e => setModuleId(e.target.value)}
          helperText="Индекс подраздела"
          fullWidth
          style={{ margin: 48 }}
        />
        <TextField
          id="componentId"
          variant="outlined"
          value={componentId}
          onChange={e => setComponentId(e.target.value)}
          helperText="Индекс контентного блока"
          fullWidth
          style={{ margin: 48 }}
        />
        <div style={{ margin: 48 }}>
          <Button
            onClick={findContentBlock}
            variant="contained"
            color="primary"
            size="large"
            style={{ width: 240, height: 50, marginBottom: 6 }}
          >
            Найти контентный блок
          </Button>
        </div>
      </div>
      <div style={{ width: 1200, padding: 48 }}>
        <Typography variant="h5" style={{ textAlign: "center", color: "red" }}>
          {helperText}
        </Typography>
        <TextField
          variant="outlined"
          value={content.index}
          onChange={e => updateContent(e.target.value, "index")}
          helperText="Index"
          type="email"
          fullWidth
          style={{ margin: 12 }}
        />
        <TextField
          variant="outlined"
          value={content.title}
          onChange={e => updateContent(e.target.value, "title")}
          helperText="Title"
          type="email"
          fullWidth
          style={{ margin: 12 }}
        />
        <TextField
          variant="outlined"
          value={content.type}
          onChange={e => updateContent(e.target.value, "type")}
          helperText="Type (optional, default: multipart-content)"
          type="email"
          fullWidth
          style={{ margin: 12 }}
        />
        {(content.type === "test" || content.type === "manual-test" || content.type === "review") && (
          <TestsEditor content={content} setContent={setContent} autocheck={content.type === "test"} />
        )}
        <Button
          onClick={createComponent}
          variant="outlined"
          color="primary"
          size="large"
          style={{ width: 150, height: 50 }}
        >
          Создать
        </Button>
      </div>
    </>
  )
}
