import React from "react"
import { Autocomplete } from "shared/ui"
import { Size } from "shared/ui/styles.const"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { IFilters } from "shared/hooks/useFiltersForm"
import { AssignmentStatus, IAssingment } from "entities/Assignment/model/assignment.types"
import { formScoreOptions } from "entities/Assignment/lib/formOptions"

interface IScoreProps {
  assignment: IAssingment
  status: string
  score?: string | number
  handleScore: (e: { target: { value: string | number; name?: string; label?: string | undefined } }) => void
}

const ParticipantScoreSelector = React.memo(({ assignment, status, score, handleScore }: IScoreProps) => {
  const filters: IFilters = useAppSelector(state => state.assignment.filters)
  const scoreOptions = formScoreOptions(assignment.block_min_score, assignment.block_max_score, false)
  const displayedScore = status === AssignmentStatus.accepted ? (score !== null ? { score: String(score) } : {}) : {}

  return (
    <Autocomplete
      id="statistics__field-mark_value"
      key={`${filters.course}+${status}`}
      name={"mark_value"}
      label="Балл"
      options={scoreOptions}
      value={displayedScore?.score}
      filterChange={handleScore}
      width={100}
      size={Size.small}
      disabled={status !== AssignmentStatus.accepted}
    />
  )
})

export { ParticipantScoreSelector }
