import React, {useState} from "react"
import {
    Button,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tabs,
    TextField,
    Typography,
} from "@mui/material"

import {axiosInstance as axios} from "shared/api/axiosInstance"
import {Card} from "shared/ui"
import {utils, writeFileXLSX} from "xlsx"

export const ParticipantStatistics = () => {
    const [courseProgress, setCourseProgress] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [helperText, setHelperText] = useState(" ")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [variantValue, setVariantValue] = useState(null)
    const [showStatistics, setShowStatistics] = useState(null)

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const emptyProgressRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - (!!courseProgress ? courseProgress.length : 0)) : 0

    function getUserProgress() {
        setIsLoading(true)
        axios
            .get(`/content/progress/`, {
                params: {
                    participant__user__email: variantValue.label,
                },
            })
            .then(res => {
                if (res.data.length === 0) {
                    setIsLoading(false)
                    setHelperText(
                        "Пользователь с таким email не участвует ни в одном формате. Проверьте правильность написания email."
                    )
                    setShowStatistics(false)
                    return
                }
                let data = res.data.map(e => ({
                    id: e.participant.course,
                    title: e.participant.course_title,
                    score: e.score,
                    completed: e.completed,
                }))
                setVariantValue({id: res.data[0].participant.user, label: variantValue.label})
                setCourseProgress(data)
                setIsLoading(false)
                setHelperText("")
            })
            .catch(err => {
                setIsLoading(false)
                setHelperText("Ошибка при загрузке")
            })
    }

    function loadStatisticsCSV() {
        setIsLoading(true)
        if (!courseProgress) {
            setHelperText("Нет данных")
            return
        }
        var scores = [...courseProgress]
        scores.unshift({id: "ID", title: "Название", score: "Баллы", completed: "Статус прохождения"})
        scores = scores.map(s => ({
            ...s,
            completed: s.completed ? "Пройдено" : "Не пройдено"
        }))
        scores = scores.map(s => Object.entries(s).map(e => e[1]))
        let ws = utils.aoa_to_sheet(scores)
        let wb = utils.book_new()
        utils.book_append_sheet(wb, ws, "Scores")
        writeFileXLSX(wb, `scores_user${variantValue.id}.xlsx`)
        setIsLoading(false)
    }

    return (
        <>
            <Card style={{display: "flex", alignItems: "center", width: "100%", gap: 40, padding: 81}}>
                <TextField
                    id="thread"
                    variant="outlined"
                    value={!!variantValue ? variantValue.label : null}
                    onChange={e => setVariantValue({id: null, label: e.target.value})}
                    placeholder="Почта пользователя"
                    style={{width: 480}}
                />
                {isLoading ? (
                    <CircularProgress size={26}/>
                ) : (
                    <>
                        <Button
                            variant="contained"
                            disabled={!variantValue}
                            onClick={() => {
                                setShowStatistics(true)
                                getUserProgress()
                            }}
                        >
                            ПОСМОТРЕТЬ СТАТИСТИКУ
                        </Button>
                        {/* <Button variant="outlined" disabled={!variantValue} onClick={() => { setShowStatistics(false); getUserProgress(); loadStatisticsCSV("other"); }}>СКАЧАТЬ</Button> */}
                    </>
                )}
            </Card>
            <Typography variant="body1">{helperText}</Typography>
            {window.innerWidth > 1200 && courseProgress && showStatistics && (
                <Card style={{width: "100%", paddingBottom: 48}}>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        padding: 64
                    }}>
                        <div>
                            {variantValue && (
                                <>
                                    <Typography variant="h5">{`Ученик - ${variantValue.label}`}</Typography>
                                    <Typography variant="body1">{`ID: ${variantValue.id}`}</Typography>
                                </>
                            )}
                        </div>
                        <Button variant="contained" onClick={() => loadStatisticsCSV()}>
                            СКАЧАТЬ СТАТИСТИКУ
                        </Button>
                    </div>
                    <TableContainer component={Paper}>
                        <Typography variant="h5" style={{marginLeft: "64px", marginBottom: "10px"}}>
                            Статистика прохождения курсов
                        </Typography>
                        <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Название</TableCell>
                                    <TableCell align="center">ID</TableCell>
                                    <TableCell align="center">Баллы</TableCell>
                                    <TableCell align="center">Статус прохождения</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {courseProgress &&
                                    courseProgress.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(e => {
                                        console.log(e)
                                        return <TableRow key={e.id}
                                                         sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                            <TableCell align="center">{`${e.title}`}</TableCell>
                                            <TableCell align="center">{`${e.id}`}</TableCell>
                                            <TableCell align="center">{`${e.score}`}</TableCell>
                                            <TableCell
                                                align="center">{`${e.completed ? "Пройдено" : "Не пройдено"}`}</TableCell>
                                        </TableRow>
                                    })}
                                {emptyProgressRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: 33 * emptyProgressRows,
                                        }}
                                    >
                                        <TableCell colSpan={6}/>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, {
                            value: !!courseProgress ? courseProgress.length : 0,
                            label: "All"
                        }]}
                        component="div"
                        count={!!courseProgress ? courseProgress.length : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            )}
        </>
    )
}
