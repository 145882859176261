import {makeStyles} from "@mui/styles";
import { margin } from "@mui/system";

export default makeStyles(theme => ({

    advantagesContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        paddingBottom: "96px",
        marginLeft: "124px",
        marginRight: "124px",
        gap: 124,

        [theme.breakpoints.down("md")] : {
            marginLeft: 100,
            marginRight: 100,
        },
        [theme.breakpoints.down("sm")] : {
            marginLeft: 20,
            marginRight: 20,
        },
    },

    advantagesContentContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "flex-start",
        [theme.breakpoints.down("md")] : {
            flexDirection: "column",
            alignItems: "center",
            gap: 80,
        },
    },

    advantageContainer: {
        gap: 40,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start",
        width: "28%",

        [theme.breakpoints.down("md")] : {
            width: "100%",
            gap: 20,
        },
        [theme.breakpoints.down("sm")] : {
            width: "100%",
        },
    },

    imageAdvantage: {
        height: 134,
        paddingBottom: 56,
        boxSizing: "content-box",

        [theme.breakpoints.down("sm")] : {
            height: 120,
            paddingBottom: 28,
        },
    },

}))
