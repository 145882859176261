import React, { useState, useEffect } from "react"
import { Button, Link } from "@mui/material"
import { useTheme } from "shared/context"
import { useUserState } from "shared/context"
//@ts-ignore
import { useHistory } from "react-router-dom"
import { Notifications } from "features"
import { timerActions } from "shared/store"
import Logo from "images/logo-eqvium.png"
import * as Styled from "./ui/Header.Styled"
import { LoginAndRegistrationContainer } from "./ui/LoginAndRegistrationContainer"
import { routes } from "./util/Routes"
import { UserMenu } from "./ui/UserMenu"
import { Drawer } from "./ui/Drawer"
import { useWindowSizeChange } from "shared/hooks"
import { fetchProfile } from "entities/User"
import { fetchParticipantStatus } from "entities/Participant/api/participant"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { useAppDispatch } from "../../shared/hooks/useAppDispatch"
import config from "../../shared/config"
import { axiosInstance as axios } from "../../shared/api/axiosInstance"

interface IProps {
  buttonText?: string
  logo?: string
  variant: "landing" | "default" | "shadow"
  slug?: string
  catalogLink?: string | null
}

export function Header({ catalogLink = null, variant, logo, slug, buttonText = "КАТАЛОГ" }: IProps) {
  const theme = useTheme()
  const history = useHistory()
  const { isSmall } = useWindowSizeChange()
  const { isAuthenticated } = useUserState()
  const [profile, setProfile] = useState(null)
  const [isStaff, setIsStaff] = useState(null)
  const [isOrganizationsModerator, setIsOrganizationsModerator] = useState(false)
  const isRunningTimerTest = useAppSelector(state => state.timer.isRunning)
  const user_id = localStorage.getItem("user")
  const status = localStorage.getItem("user_status")
  const dispatch = useAppDispatch()
  const [organization, setOrganization] = useState<{ logo: string; icon_logo: string } | null>(null)

  useEffect(() => {
    const windowUrl = window.location.origin
    if (!windowUrl.split("//")[1].includes(config.hostApi.split("//")[1])) {
      axios.get("organizations", { params: { domain: windowUrl.split("//")[1] } }).then(res => {
        setOrganization(res.data[0])
      })
    }
  }, [])

  useEffect(() => {
    if (user_id) {
      fetchProfile().then(res => {
        setProfile(res.data)
        localStorage.setItem("user_status", res.data.status)
        axios.get("/organizations/is_organizations_moderator").then(res => {
          setIsOrganizationsModerator(res.data.is_organizations_moderator)
        })
      })
      fetchParticipantStatus().then(res => {
        setIsStaff(res.data.is_staff)
      })
    }
  }, [user_id])

  const redirect = (link: string) => {
    const isPreventing = preventLeaving(link)
    if (isPreventing) return
    history.push(link)
  }

  const redirectOnLogo = () => {
    if (variant === "landing") return
    if (!!organization) window.location.href = "/"
    if (!slug) window.location.href = "/"
    else window.location.href = `/organization/${slug}`
  }

  const preventLeaving = (link: string) => {
    if (isRunningTimerTest) {
      dispatch(timerActions.setIsExitModal(true))
      link && dispatch(timerActions.setExitLink(link))
    }
    return isRunningTimerTest
  }

  function isActive(field: string) {
    if (history.location.pathname.slice(1).includes(field)) return "#DEE7F2"
    else return null
  }

  if (isSmall === null) return <></>

  return (
    <Styled.LandingAppBar
      sx={{
        boxShadow: variant === "landing" ? "none" : "0 2px 4px rgb(0 0 0 / 5%)",
        position: variant === "default" ? "absolute" : "fixed",
      }}
    >
      <Styled.LandingToolbar>
        <Styled.LandingLogoContainer>
          {/*@ts-ignore*/}
          <Styled.LandingLogo
            isOrganizationLogo={
              !!logo || !window.location.origin.split("//")[1].includes(config.hostApi.split("//")[1])
            }
            src={
              window.location.origin.split("//")[1].includes(config.hostApi.split("//")[1]) && !logo
                ? Logo
                : organization?.logo || logo
            }
            sx={variant !== "landing" ? { cursor: "pointer" } : {}}
            onClick={redirectOnLogo}
          />
        </Styled.LandingLogoContainer>
        {isSmall && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 4,
              width: "30%",
            }}
          >
            {isAuthenticated && <Notifications color="#000000" />}
            <Drawer
              hasOrganizations={isOrganizationsModerator}
              catalogLink={catalogLink || null}
              buttonText={buttonText || "КАТАЛОГ"}
              isActive={isActive}
              profile={profile}
              status={status as string}
              isStaff={isStaff}
            />
          </div>
        )}
        {!isSmall && (
          <>
            <Button component={Link} onClick={() => redirect(!!catalogLink ? catalogLink : routes.catalog)}>
              <div
                style={{
                  fontWeight: 400,
                  fontSize: "16px",
                  color: "#000",
                }}
              >
                {buttonText}
              </div>
            </Button>
            {isAuthenticated && (
              <Styled.AuthUserContainer>
                <Notifications color="#000000" />
                <UserMenu
                  hasOrganizations={isOrganizationsModerator}
                  isActive={isActive}
                  profile={profile}
                  status={status as string}
                  isStaff={isStaff}
                />
              </Styled.AuthUserContainer>
            )}
            <LoginAndRegistrationContainer />
          </>
        )}
      </Styled.LandingToolbar>
    </Styled.LandingAppBar>
  )
}
