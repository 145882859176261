import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material"
import { useAppSelector } from "shared/hooks/useAppSelector"
import ParticipantInfoEditor from "widgets/Participants/ParticipantInfoEditor"

interface IProps {
  editParticipantDialog: boolean | null
  setEditParticipantDialog: (x: null) => void
  infoFields: { id: string; title: string }[] | null
}

const EditPaticipantModal = ({ editParticipantDialog, setEditParticipantDialog, infoFields }: IProps) => {
  const course = useAppSelector(state => state.course.course)

  const onClose = () => {
    setEditParticipantDialog(null)
  }

  return (
    <Dialog
      aria-labelledby="DialogTitle"
      aria-describedby="DialogDescription"
      open={!!editParticipantDialog}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogContent style={{ height: 600 }}>
        <DialogContentText id="DialogDescription" style={{ height: "100%" }}>
          <ParticipantInfoEditor user={editParticipantDialog} course={course?.id} infoField={infoFields} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Закрыть</Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditPaticipantModal
