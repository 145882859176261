import React, { useEffect, useState } from "react"
import { Autocomplete, Button, Modal, TextField, Typography } from "shared/ui"
import { useDispatch, useSelector } from "react-redux"
import { organizationActions } from "shared/store"
import * as Styled from "./ui/Organizations.styles"
import { updateOrganization } from "entities/Organization"
import { FileUploader } from "shared/assets"
import { useTheme } from "shared/context"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import { Checkbox, FormControlLabel } from "@mui/material"

const organizationCategories = [
  { value: "UNIVERSITY", label: "Высшее учебное заведение" },
  { value: "SCHOOL", label: "Школа" },
  { value: "TECHNOPARK", label: "Технопарк" },
  { value: "EDUCATIONCENTER", label: "Центр дополнительного образования" },
  { value: "COLLEGE", label: "Колледж" },
  { value: "NCO", label: "Некоммерческая организация" },
  { value: "COMPANY", label: "Компания" },
]
const EditOrganization = () => {
  const theme = useTheme()
  const isOpen = useSelector(state => state.organization.editOrganization)
  const dispatch = useDispatch()
  const organization = useSelector(state => state.organization.currentOrganization)
  const [params, setParams] = useState(null)
  const [status, setStatus] = useState("")

  useEffect(() => {
    if (!!organization) {
      let data = { ...organization }
      delete data["logo"]
      delete data["logo_negative"]
      delete data["icon_logo"]
      setParams({ ...data })
    }
    axios.get("/profiles/my_profile").then(res => {
      setStatus(res.data.status)
    })
  }, [organization])

  function handleChangeIconLogo(image, field) {
    params["icon_logo"] = image
    setParams({ ...params })
  }

  function handleChangeLogo(image, field) {
    params["logo"] = image
    setParams({ ...params })
  }

  function handleChangeLogoNegative(image, field) {
    params["logo_negative"] = image
    setParams({ ...params })
  }

  function closeEdit() {
    dispatch(organizationActions.setEditOrganization(false))
  }

  return (
    <Modal isOpen={isOpen} onClose={closeEdit} title={"Изменение информации об организации"}>
      <Styled.EditOrganizationMainDiv>
        <TextField
          variant="outlined"
          disabled={true}
          value={organization?.title}
          label={"Название организации"}
          style={{ width: "100%" }}
        />
        <TextField
          variant="outlined"
          disabled={false}
          value={params?.information}
          onChange={event => {
            params.information = event.target.value
            setParams({ ...params })
          }}
          label={"Описание организации"}
          style={{ width: "100%" }}
        />
        <TextField
          variant="outlined"
          value={params?.website}
          onChange={event => {
            params.website = event.target.value
            setParams({ ...params })
          }}
          label={"Сайт организации"}
          style={{ width: "100%" }}
        />
        {status === "admin" && (
          <>
            <TextField
              variant="outlined"
              value={params?.domain}
              onChange={event => {
                params.domain = event.target.value
                setParams({ ...params })
              }}
              label={"Домен организации"}
              style={{ width: "100%" }}
            />
            <TextField
              variant="outlined"
              value={params?.slug}
              onChange={event => {
                params.slug = event.target.value
                setParams({ ...params })
              }}
              label={"Уникальное название организации"}
              style={{ width: "100%" }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={params?.has_main_page}
                  defaultChecked
                  onChange={event => {
                    params.has_main_page = event.target.checked
                    setParams({ ...params })
                  }}
                />
              }
              label="Лэндинг организации"
            />
          </>
        )}
        <div>
          <Typography variant={"h4"} sx={{ marginBottom: theme.spacing(4) }}>
            Загрузить иконку организации (отображается на лэндинге организации)
          </Typography>
          <FileUploader setImage={handleChangeIconLogo} />
        </div>
        <div>
          <Typography variant={"h4"} sx={{ marginBottom: theme.spacing(4) }}>
            Загрузить лого (отображается на странице события)
          </Typography>
          <FileUploader setImage={handleChangeLogo} />
        </div>
        <div>
          <Typography variant={"h4"} sx={{ marginBottom: theme.spacing(4) }}>
            Загрузить белое лого (отображается на странице события)
          </Typography>
          <FileUploader setImage={handleChangeLogoNegative} />
        </div>
        <Styled.EditCategory>
          <Typography variant={"h4"}>Категория организации</Typography>
          <Autocomplete
            label={"Название категории"}
            options={organizationCategories}
            value={params?.category}
            onChange={(event, newValue) => {
              params.category = newValue.label
              setParams({ ...params })
            }}
            width={225}
          />
        </Styled.EditCategory>
        <Button
          variant={"contained"}
          color={"primary"}
          sx={{ alignSelf: "flex-end" }}
          onClick={() => dispatch(updateOrganization(params))}
        >
          Изменить
        </Button>
      </Styled.EditOrganizationMainDiv>
    </Modal>
  )
}

export default EditOrganization
