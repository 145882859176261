export const settingsConfig = (type: "event" | "webinar") => {
  return {
    id: { visibility: false },
    stages: { visibility: false },
    info_fields: { visibility: false },
    affiliations_info: { visibility: false },
    teachers: { visibility: false },
    title: { visibility: true },
    type: { visibility: true },
    badge: { visibility: type !== "event" },
    slug: { visibility: true },
    subtitle: { visibility: true },
    description: { visibility: true },
    access_type: { visibility: true },
    visibility: { visibility: true },
    min_score: { visibility: true },
    max_score: { visibility: true },
    image: { visibility: type !== "event" },
    is_active: { visibility: true },
    start: { visibility: true },
    finish: { visibility: true },
    registration_type: { visibility: true },
    registration_start: { visibility: true },
    registration_finish: { visibility: true },
    is_registration_opened: { visibility: true },
    last_modified: { visibility: false },
    template: { visibility: true },
    template_props: { visibility: false },
    is_for_teams: { visibility: true },
    team_props: { visibility: false },
    use_info: { visibility: true },
    info_description: { visibility: true },
  }
}
